(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["en-ZA"] = {
            name: "en-ZA",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": " ",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "South African Rand",
                    abbr: "ZAR",
                    pattern: ["-$n","$n"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "R"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        namesAbbr: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        namesShort: ["Su","Mo","Tu","We","Th","Fr","Sa"]
                    },
                    months: {
                        names: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        namesAbbr: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                    },
                    AM: ["am","am","AM"],
                    PM: ["pm","pm","PM"],
                    patterns: {
                        d: "yyyy/MM/dd",
                        D: "dddd, dd MMMM yyyy",
                        F: "dddd, dd MMMM yyyy HH:mm:ss",
                        g: "yyyy/MM/dd HH:mm",
                        G: "yyyy/MM/dd HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 0
                }
            }
        };
    })();

}));
